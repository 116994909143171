<template>
  <div class="user-my-questionnaire" v-wechat-title="'我的问卷'">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="暂无更多数据"
      @load="onLoad"
    >
      <div class="user-my-questionnaire-list">
        <div v-for="(item,index) in questionnaireList" :key="index">
          <span>
            <v-img :attachment-id="item.questionnaireCoverAttmId" :default-img="require('@/assets/img/111@2x.png')" alt="questionnaire" />
          </span>
          <div>
            <span>{{ item.questionnaireName }}</span>
            <p>
              {{ item.fillTotal + 20 }}人已参与
              <span>{{ item.tenantName }}</span>
            </p>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getQuestionResponseList } from '@/api/response'
import VImg from '@/components/VImg'

export default {
  components: { VImg },
  data () {
    return {
      page: {
        current: 1,
        size: 10,
        pageSize: 1
      },
      loading: false,
      finished: false,
      questionnaireList: []
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    onLoad () {
      if (!this.questionnaireList || !this.questionnaireList.length) {
        this.finished = true
        return
      }
      if (this.page.pageSize === this.page.current) {
        this.finished = true
        return
      }
      this.page.current++
      this.loading = true
      this.loadData()
    },
    // 解决方案列表
    loadData () {
      const { page } = this
      const data = {}
      getQuestionResponseList(page, data).then((res) => {
        this.loading = false
        this.questionnaireList = [...this.questionnaireList, ...res.records]
        this.page.pageSize = res.pages
        this.finished = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-my-questionnaire{
  padding: 16px;
  min-height: calc(100vh - 56px);
  background: #f6f6f6;
  .user-my-questionnaire-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    >div{
      width: 167px;
      border-radius: 4px;
      margin-bottom: 10px;
      box-shadow: 0px 0px 2px rgba(0,0,0,0.0500);
      background: #fff;
      overflow: hidden;
      >span{
        display: block;
        height: 73px;
        width: 100%;
        >img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      >div{
        padding: 8px 12px;
        >span{
          font-size: 14px;
          line-height: 20px;
          color: #333333;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 8px;
        }
        >p{
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #666;
          font-size: 12px;
          line-height: 16px;
          >span{
            height: 19px;
            background: #F7F7F7;
            border-radius: 2px;
            padding: 0 6px;
            font-size: 12px;
            line-height: 19px;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
