import { PortalRequest as request } from '@/utils/request'

// 分页查询我的问卷答复列表
export const getQuestionResponseList = (params, data) => {
  return request({
    url: '/response/list/me',
    method: 'post',
    params: params,
    data
  })
}
